#myworkdiv {
  margin-top: 85px;
  padding-left: 15vw;
  padding-right: 15vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 20px;
  row-gap: 30px;
}

#projects {
  margin-bottom: 30px;
}
@media only screen and (max-width: 849px) {
  .typemaster {
    margin-bottom: 75px !important;
  }
  #myworkdiv {
    justify-content: center;
  }
  #footer {
    text-align: center;
  }
}

@media only screen and (max-width: 571px) {
  #projects {
    display: flex !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  #projectimage {
    width: 250px !important;
  }
  #projectdiv {
    width: 300px !important;
    height: 500px !important;
    border-radius: 30px !important;
    margin-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 390px) {
  #projects {
    display: flex !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  #projecttitle {
    font-size: 25px;
  }
  #projectimage {
    width: 200px !important;
  }
  #projectdiv {
    width: 200px;
    height: 410px !important;
    border-radius: 30px !important;
    margin-bottom: 1px;
  }
  .extended {
    height: 450px !important;
  }
}

@media only screen and (max-width: 490px) {
  #logoimg2 {
    display: none !important;
  }
}
