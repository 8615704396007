html {
  overflow-x: hidden;
}
@media only screen and (max-width: 1650px) {
  #title {
    font-size: 60px !important;
  }

  h3 {
    font-size: 25px !important;
  }
  #headshot {
    width: 300px;
    margin-top: 0 !important;
  }
  #myworkdiv {
    column-gap: 60px;
    justify-content: center !important;
  }
  .scrolldiv h1 {
    font-size: 25px;
  }

  #homediv {
    column-gap: 50px !important;
  }
}

@media only screen and (max-width: 916px) {
  #myworkdiv {
    column-gap: 30px;
    justify-content: center !important;
  }
}

@media only screen and (max-width: 1200px) {
  #homediv {
    display: block !important;
    padding: 0 !important;
  }

  #homedivwrapper {
    display: flex;
    justify-content: center;
  }
  #headshotdiv {
    display: flex;
    justify-content: center;
  }
  .dot {
    display: none;
  }
  #title {
    font-size: 60px !important;
  }

  h3 {
    font-size: 25px !important;
  }
  #headshot {
    margin-top: 15px !important;
  }
  #links {
    display: none !important;
  }
  #logo {
    margin-left: 0 !important;
  }
  #nav {
    width: 70vw !important;
    padding-left: 15vw !important;
    padding-right: 15vw !important;
  }
}

/* @media only screen and (max-width: 900px) {
  #myworkdiv {
    column-gap: 15px;
  }
} */
/* 
/*  */
@media only screen and (max-width: 415px) {
  #title {
    text-align: center;
  }
  h3 {
    text-align: center;
    font-size: 10px !important;
  }
}
@media only screen and (max-width: 425px) {
  #title {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
  h3 {
    text-align: center;
    font-size: 20px !important;
  }
  #headshot {
    width: 250px !important;
  }
}

@media only screen and (max-width: 325px) {
  h3 {
    text-align: center;
    font-size: 18px !important;
  }
}

#unavailable {
  display: block;
  margin-top: 200px;
}
#unavailabletext {
  color: white;
  font-weight: 600;
  font-size: 40px;
  text-align: center;
}
#sorry {
  color: white;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  #title {
    text-align: center;
  }
  h3 {
    text-align: center;
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 540px) {
  #title {
    text-align: center;
    font-size: 40px !important;
  }
  h3 {
    text-align: center;
    font-size: 18px !important;
  }

  #headshot {
    width: 250px !important;
  }
}

@media only screen and (max-width: 460px) {
  #title {
    text-align: center;
    font-size: 40px !important;
  }
  h3 {
    text-align: center;
    font-size: 16px !important;
  }

  h3 > br {
    display: none;
  }
}

@media only screen and (max-width: 375px) {
  #title {
    text-align: center;
    font-size: 40px !important;
  }
  h3 {
    text-align: center;
    font-size: 16px !important;
    margin-inline: 20px;
  }
  h3 > br {
    display: none;
  }
}
