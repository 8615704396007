#figmaprojects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
  column-gap: 30px;
  row-gap: 50px;
  padding-left: 50px;
  padding-right: 50px;
}

#essayhub {
  width: 500px;
}

#scoopfigma {
  width: 958px;
}

@media only screen and (max-width: 1057px) {
  #nike {
    width: 500px;
  }

  #scoopfigma {
    width: 500px;
  }
}

@media only screen and (max-width: 600px) {
  #essayhub {
    width: 300px;
  }
  #nike {
    width: 300px;
  }

  #scoopfigma {
    width: 300px;
  }
}

#scoopfigma {
  margin-bottom: 100px;
}
