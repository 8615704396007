* {
  scroll-behavior: smooth;
}

html {
  top: 300px;
  width: 100vw;
  height: 100vh;
}

#nav {
  font-family: "Inter", sans-serif;
  top: 0;
  left: 0;
  padding-left: 10vw;
  padding-right: 10vw;
  background-color: #010030;
  width: 80vw;
  height: 95px;
  position: fixed;
  border-bottom: 2px solid rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#links {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
}

#links a {
  color: white;
  text-decoration: none;
  font-size: 20px;
  font-weight: 500;
}

#home {
  top: 125px;
}

#logoimg {
  width: 100px;
  margin-top: 3px;
  display: flex;
  align-items: center;
}

#logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -180px;
}

#icons {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-top: 13px;
}

#github {
  width: 30px;
}
