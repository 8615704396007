#projectimage {
  width: 400px;
  margin-top: 15px;
  border-radius: 20px;
}

#projecttitle {
  display: flex;
  justify-content: center;
  color: #fff;
}

#projectdiv {
  background-color: rgba(0, 0, 0, 0.328);
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 400px;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  border-radius: 40px;
  margin-bottom: 75px;
}

.my-custom-button2 {
  background-color: #0026ff; /* Green */
  border: none;
  color: white;
  padding: 16px 34px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  border-radius: 10px;
  margin-top: 30px;
}

.my-custom-button2:hover {
  background-color: rgb(39, 118, 255);
  transition: background-color 100ms ease;
  color: #ffffff;
}
