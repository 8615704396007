* {
  scroll-behavior: smooth;
}

html {
  top: 300px;
  width: 100vw;
  height: 100vh;
}

#nav2 {
  font-family: "Inter", sans-serif;
  background-color: #010030;
  top: 0;
  left: 0;
  padding-left: 20vw;
  padding-right: 20vw;
  width: 60vw;
  height: 95px;
  position: fixed;
  border-bottom: 2px solid rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#logoimg2 {
  width: 100px;
  margin-top: 3px;
  display: flex;
  margin-left: 40px;
  align-items: center;
}

#logo2 {
  display: flex;
  align-items: center;
}

#icons2 {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-top: 13px;
}

#github2 {
  width: 30px;
}
