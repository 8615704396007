#aboutme {
  display: flex;
  justify-content: center;
  color: white;
  font-size: 35px;
}

#paragraph {
  color: white;
  font-size: 26px;
  font-weight: 400;
  padding-left: 15vw;
  padding-right: 15vw;
  text-align: justify;
  word-spacing: -2px;
  margin-bottom: 100px;
}

#h3 {
  font-size: 25px;
  font-weight: 500;
  display: flex;
  justify-content: center;
}

#resumebutton {
  background: #fff;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 15px;
  cursor: pointer;
  text-decoration: none;
}

#downloadresume {
  text-decoration: none;
}

#buttonwrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-custom-button {
  background-color: #0026ff; /* Green */
  border: none;
  color: white;
  padding: 16px 34px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  border-radius: 10px;
  margin-bottom: 105px;
}

.my-custom-button:hover {
  background-color: rgb(39, 118, 255);
  transition: background-color 100ms ease;
  color: #ffffff;
}
