html {
  background-image: url("../src/Assets/background6.jpg");
  background-size: cover;
  height: 100svh;
  background-repeat: no-repeat;
}
#homedivwrapper {
  display: flex;
  justify-content: center;
}

#homediv {
  display: flex;
  column-gap: 200px;
}

#title {
  color: white;
  font-size: 80px;
  font-weight: 400;
}

h3 {
  color: white;
  font-size: 35px;
  font-weight: 400;
}

img {
  margin-top: -20px;
}

.scrolldiv {
  margin-top: 100px;
  border-top: solid 2px white;
  border-bottom: solid 2px white;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
}

.scrolldiv h1 {
  z-index: -1;
  white-space: nowrap;
  padding: 0 2rem;
  font-weight: 400;
  color: white;
  animation: scrollefect 4000ms linear infinite;
}

@keyframes scrollefect {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

h4 {
  color: rgba(255, 255, 255, 0);
}

#headshot {
  width: 400px;
  border-radius: 100%;
  margin-top: 60px;
}
