#designprojects {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
  row-gap: 50px;
  column-gap: 30px;
}

#panels {
  margin-bottom: 150px;
}

.designimage {
  width: 1500px;
}

@media only screen and (max-width: 1575px) {
  #designprojects {
    row-gap: 45px;
  }
  .designimage {
    width: 1000px;
  }
}

@media only screen and (max-width: 1100px) {
  #designprojects {
    row-gap: 40px;
  }
  .designimage {
    width: 800px;
  }
}
@media only screen and (max-width: 835px) {
  #designprojects {
    row-gap: 35px;
  }
  .designimage {
    width: 600px;
  }
}
@media only screen and (max-width: 630px) {
  #designprojects {
    row-gap: 30px;
  }
  .designimage {
    width: 500px;
  }
}

@media only screen and (max-width: 525px) {
  #designprojects {
    row-gap: 25px;
  }
  .designimage {
    width: 450px;
  }
}
@media only screen and (max-width: 470px) {
  .designimage {
    width: 400px;
  }
}
@media only screen and (max-width: 410px) {
  #designprojects {
    row-gap: 25px;
  }
  .designimage {
    width: 350px;
  }
}
@media only screen and (max-width: 320px) {
  #designprojects {
    row-gap: 25px;
  }
  .designimage {
    width: 300px;
  }
}
