#conceptprojects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
  padding-left: 15vw;
  padding-right: 15vw;
  row-gap: 50px;
  column-gap: 40px;
}

.conceptimage {
  width: 40%;
  height: 40%;
}

@media only screen and (max-width: 1575px) {
  #conceptprojects {
    row-gap: 45px;
  }
  .conceptimage {
    width: 35%;
    height: 35%;
  }
}

@media only screen and (max-width: 1100px) {
  #conceptprojects {
    row-gap: 40px;
  }
  .conceptimage {
    width: 800px;
  }
}
@media only screen and (max-width: 835px) {
  #conceptprojects {
    row-gap: 35px;
  }
  .conceptimage {
    width: 600px;
  }
}
@media only screen and (max-width: 630px) {
  #conceptprojects {
    row-gap: 30px;
  }
  .conceptimage {
    width: 500px;
  }
}

@media only screen and (max-width: 525px) {
  #conceptprojects {
    row-gap: 25px;
  }
  .conceptimage {
    width: 450px;
  }
}
@media only screen and (max-width: 470px) {
  .conceptimage {
    width: 400px;
  }
}
@media only screen and (max-width: 445px) {
  #conceptprojects {
    row-gap: 25px;
  }
  .conceptimage {
    width: 300px;
  }
}
@media only screen and (max-width: 335px) {
  #conceptprojects {
    row-gap: 25px;
  }
  .conceptimage {
    width: 250px;
  }
}
