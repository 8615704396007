.workcomponent {
  background-color: rgba(0, 0, 0, 0.328);
  width: 230px;
  height: 300px;
  padding: 30px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#workcomponenttitle {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  margin-top: -5px;
}

#viewprojects {
  background-color: rgb(27, 46, 254);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 100px;
  font-weight: 500;
  margin-top: 30px;
  border-radius: 10px;
  color: #fff;
  text-decoration: none;
}
#viewprojectsa {
  text-decoration: none;
}

#viewprojects:hover {
  background-color: rgb(39, 118, 255);
  transition: background-color 100ms ease;
}

#webdev {
  width: 180px;
  height: 150px;
  margin-top: -2px;
  border-radius: 15px;
}

#other {
  margin-bottom: 100px;
}
