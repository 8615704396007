#pjcttitle {
  display: flex;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  text-align: center;
}

#titlenav {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 15vw;
  padding-right: 15vw;
  margin-top: 125px;
}

#homeimage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  margin-top: 0px;
}

#projects {
  padding-left: 15vw;
  padding-right: 15vw;
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 50px;
  row-gap: 50px;
}

#typemaster {
  margin-bottom: 150px;
}
